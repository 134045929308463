var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "order-type relative flex a-center j-center" },
    [
      _vm.imgUrl.includes("takeaway") || _vm.imgUrl.includes("pickup")
        ? _c("img", {
            attrs: { src: _vm.imgUrl, width: "15px", height: "15px" },
          })
        : _c("img", {
            attrs: { src: _vm.imgUrl, width: "20px", height: "20px" },
          }),
      _vm.data.futureOrder
        ? _c("img", {
            staticClass: "absolute",
            attrs: { src: require("../../../assets/img/svg/time-future.svg") },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }